<template>
  <path id="Tracé_568" data-name="Tracé 568" d="M5.333,63.993a5.312,5.312,0,0,1-3.563-1.37A5.262,5.262,0,0,1,0,58.659V23.994a1.34,1.34,0,0,1,.517-1.056L8,17.152V10.661a5.34,5.34,0,0,1,5.333-5.333h9.946l5.453-4.215a5.356,5.356,0,0,1,6.533,0l5.455,4.216h9.946A5.339,5.339,0,0,1,56,10.661v6.492l7.484,5.786A1.34,1.34,0,0,1,64,23.994V58.659a5.351,5.351,0,0,1-5.333,5.333Zm-.082-2.674c.027,0,.054.008.081.008H58.667c.027,0,.054-.006.082-.008L37.679,45.013l-2.415,1.869a5.339,5.339,0,0,1-6.529,0l-2.415-1.869ZM39.857,43.326,61.094,59.762h0a2.657,2.657,0,0,0,.238-1.1V26.711ZM2.668,58.659a2.675,2.675,0,0,0,.236,1.1L24.142,43.326,2.668,26.711Zm8-48V29.529l19.7,15.243a2.669,2.669,0,0,0,3.265,0l19.7-15.243V10.661a2.668,2.668,0,0,0-2.665-2.666H13.333A2.669,2.669,0,0,0,10.666,10.661ZM56,27.465l4.488-3.471L56,20.524ZM3.513,23.994,8,27.465V20.524ZM30.361,3.224l-2.723,2.1h8.725L33.638,3.222a2.656,2.656,0,0,0-3.277,0Zm1.452,32.768A10.665,10.665,0,0,1,32,14.663h.192A10.682,10.682,0,0,1,42.665,25.518a4.718,4.718,0,0,1-2.353,4.417,6.285,6.285,0,0,1-5.525-.219,5.341,5.341,0,0,1-4.82.142,4.035,4.035,0,0,1-2.073-2.76,6.254,6.254,0,0,1,1.079-4.882,5.261,5.261,0,0,1,6.6-1.293,1.332,1.332,0,0,1,.706,1.2l-.094,5.3a3.585,3.585,0,0,0,2.872.155A2.228,2.228,0,0,0,40,25.469a8.008,8.008,0,0,0-7.857-8.14H32a8,8,0,0,0-.14,16,1.333,1.333,0,0,1-.024,2.666ZM31.12,23.8a3.638,3.638,0,0,0-.625,2.707,1.4,1.4,0,0,0,.721,1,2.587,2.587,0,0,0,2.3-.149l.076-4.334a3.13,3.13,0,0,0-.6-.06A2.286,2.286,0,0,0,31.12,23.8Z" transform="translate(0.5 0.66)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
  name: "IconSuiviEnLigne"
}
</script>

<style scoped>

</style>